
<template>
    <BackofficeBase :loader_prop="loader_prop">
      <CourierCompanyMenu :courier_company_id_prop="courier_company_id" />
        <!--<b>CourierCompanyDetails</b>-->
        <!--<p>courier_company_id: {{courier_company_id}}</p>
        <p>routeId: {{$route.query.id}}</p>-->

        <!--<router-link
          :to="{
            path: '/backoffice/courier_Company_management',
            query: { id: view_data.courier_company_data.id },
          }"
          >ניהול יחידות</router-link
        >-->
        <!--<br />
        <router-link
          :to="{
            path: '/backoffice/viewing_endusers_admin',
            query: { id: view_data.distribution_point_data.id },
          }"
        >
          צפייה בלקוחות קצה</router-link
        >
        <br />-->
        <div>
            <router-link
              :to="{
                path: '/backoffice/courier_company_edit',
                query: { id: view_data.id },
              }"
              >עריכת חברת הפצה</router-link
            >
          </div>
          <div>
            <router-link
              :to="{
                path: '/backoffice/BackofficeCourierCompanyChangePassword',
                query: { id: view_data.id },
              }"
              >שינוי סיסמה חברת הפצה</router-link
            >
          </div>
        <!--<router-link
          :to="{
            path: '/backoffice/backoffice_login_as_point',
            query: { id: view_data.distribution_point_data.id },
          }"
        >-->
          <s>כניסה כמשתמש חברת הפצה</s>
          <!--</router-link
        >-->
        <div v-if="view_data != null">
          
        <p class="unis_title">{{ view_data.name }} :שם חברה</p>
        <p>{{ view_data.address }} :כתובת</p>
        <p>{{ view_data.user_name_initial }} :קידומת שם משתמש שליח</p>
        <p>{{ view_data.courier_count }} :מספר שליחים</p>
        <p>{{ view_data.ts.slice(0, 10) }} :תאריך הצטרפות</p>
        <p v-if="view_data.status == 1">סטטוס: פעילה</p>
        <p v-else>סטטוס: לא פעילה</p>

        <div class="point_deatails">
          <p class="unis_title">:פרטי איש קשר</p>
          <p>{{ view_data.contact_name }} :שם</p>
          <p>{{ view_data.email }} :אימייל</p>
          <p>{{ view_data.contact_phone }} :טלפון</p>

          <p class="unis_title">:פרטי איש טכני</p>
          <p>{{ view_data.tech_contact_name }} :שם</p>
          <p>{{ view_data.tech_contact_email }} :אימייל</p>
          <p>{{ view_data.tech_contact_phone }} :טלפון</p>       
          
          <p class="unis_title">:פרטי איש הנה"ח</p>
          <p>{{ view_data.accounting_contact_name }} :שם</p>
          <p>{{ view_data.accounting_contact_email }} :אימייל</p>
          <p>{{ view_data.accounting_contact_phone }} :טלפון</p>          

        </div>
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  import CourierCompanyMenu from "./CourierCompanyMenu";
  
  export default {
    name: "BackofficeCourierCompanyDetails",
    components: {
      BackofficeBase,
      CourierCompanyMenu,
    },
  
    data() {
      return {
        loader_prop: true,
        view_data: [],
        // courier_company_id_prop : 0,
        courier_company_id: "",
      };
    },
  
    async mounted() {
      // this.id = this.$route.query.id
      console.log("route id: " + this.$route.query.id);
      this.courier_company_id = this.$route.query.id;
      let backendModel = new BackendModel();
      let view_data = await backendModel.backendRequest(
        "/Api/service/backoffice/get_courier_company",
        { id: this.courier_company_id }
      );

      this.view_data = view_data.data.view_data[0];
      
      this.loader_prop = false;
    }
  };
  </script>
  
  <style scoped>
  @import "../../assets/style.css";
  </style>
  
  